<template>
  <div />
</template>

<script>
import router from '@/router'

export default {
  beforeMount() {
    this.$store.dispatch(
      'auth/confirmRegistration',
      {
        userId: this.$route.params.userId,
        confirmationCode: this.$route.params.confirmationCode,
      },
    )
      .then(() => {
        window.toast.notify.success(this.$t('auth.register.confirmed'))
      })
      .finally(() => router.push({ name: 'login' }))
  },
}
</script>
